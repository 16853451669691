

































































































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import OfflineTimeslotPicker from "~/components/course/offline-timeslot-picker.vue";
import PageHeader from "~/components/app/page-header.vue";
import {Course, minAppliedOptions, NoteLanguage, weekDayOptions} from '~/components/data-class/data-class'
import {ClassSchedule, LessonTimeSlot, OfflineClass, OfflineClassType, OfflineEvent, OfflineLesson} from '~/components/course/offline-course-model'
import {VTextarea} from 'vuetify/lib'
import {createRequest} from "~/utils/network-request";
import {toInstanceForce} from "~/utils/Serializer";

@Component({
    components: {
        OfflineTimeslotPicker,
        PageHeader,
        VTextarea
    },
})
export default class OfflineClassBasicEditor extends Vue {
    name: 'offline-class-basic-editor'

    @Prop({default: () => new OfflineClass()}) offlineClass: OfflineClass
    @Prop({default: false}) isTrialCourse: boolean
    @Prop({default: () => []}) classIds: number[] // Class ids of all classes of the same course, no matter the version of the course that the class belongs to. It is needed to check that the current class' id was not used before.

    // For batch add or edit.
    @Prop({default: false}) hideClassId: boolean
    @Prop({default: false}) hideClassTitle: boolean

    originalClassId: number | undefined // The original class ID, only applicable to editing class.
    formList = Array(6).fill(0).map((_, i) => i + 1)

    OfflineClass = OfflineClass
    OfflineClassType = OfflineClassType
    NoteLanguage = NoteLanguage

    mounted() {
        this.init()
    }

    init() {
        this.originalClassId = this.offlineClass.id
        if (!this.originalClassId) {
            // New class.

            // Set offline class title default prefix.
            this.offlineClassTypeChanged(this.offlineClass.type)
        }
    }

    @Watch('offlineClass', {deep: false})
    watchOfflineClass(newVal, oldVal) {
        if (newVal) {
            this.init()
        }
    }

    @Watch('classIds', {deep: true})
    watchClassIds(newVal, oldVal) {
        if (newVal) {
            if (!this.originalClassId) {
                // New class.

                // Use the lowest available id.
                this.offlineClass.id = this.lowestAvailableID
            }
        }
    }

    get isTrial() {
        return this.isTrialCourse
    }

    get lowestAvailableID() {
        if (this.classIds.includes(1)) {
            const lowestIDBeforeSlot = this.classIds.find((id, index, classIds) => (index < classIds.length - 1) ? (id + 1) < classIds[index + 1] : false)
            return lowestIDBeforeSlot ? lowestIDBeforeSlot + 1 : this.classIds[this.classIds.length - 1] + 1
        }
        return 1
    }

    get quotaDisplayOptions(): { min: number, max: number, label: string, color: string } {
        const percentage = Math.min(this.offlineClass.min_applied * 100 / Math.max(this.offlineClass.quota, 1), 100)
        return minAppliedOptions.find((opt) => (opt.min <= percentage && (percentage < opt.max || (percentage === opt.max && opt.min === opt.max)))) ||
            {min: 0, max: 0, label: '', color: ''}
    }

    get levelFrom() {
        return this.offlineClass.form[0] || 1
    }

    set levelFrom(form) {
        this.offlineClass.form = Array(Math.max(this.levelTo, form) - form + 1).fill(form).map((v, i) => v + i)
    }

    get levelTo() {
        return this.offlineClass.form.length > 1 ? this.offlineClass.form[this.offlineClass.form.length - 1] : 0
    }

    set levelTo(form) {
        this.offlineClass.form = Array(Math.max(this.levelFrom, form) - this.levelFrom + 1).fill(this.levelFrom).map((v, i) => v + i)
    }

    fromChanged() {
        if (this.levelFrom >= this.levelTo)
            this.levelTo = 0
    }

    formDisabled(form) {
        return this.levelFrom >= form
    }

    offlineClassTypeChanged(classType) {
        this.offlineClass.type = classType
        if (!this.$route.params.class_id) {
            if (this.offlineClass.type !== OfflineClassType.VIDEO) {
                this.offlineClass.title = OfflineClass.offlineClassTypeToDisplaySimpleString(this.offlineClass.type)
            }
            else {
                this.offlineClass.title = ""
            }
        }
    }

    offlineClassIdInputValueChanged() {
        if (this.offlineClass.id.toString() === '') {
            // User deliberately cleared the class id.
            this.offlineClass.id = this.originalClassId ? this.originalClassId : this.lowestAvailableID
            this.$message.error('ID不能留空！自動轉至可用ID！')
            return
        }

        if (this.offlineClass.id <= 0) {
            this.offlineClass.id = this.originalClassId ? this.originalClassId : this.lowestAvailableID
            this.$message.error('班次ID不能少於或等於0！自動轉至可用ID！')
            return
        }

        if (this.originalClassId) {
            // Edit class
            if (this.classIds.includes(this.offlineClass.id) && this.originalClassId !== this.offlineClass.id) {
                this.offlineClass.id = this.originalClassId
                this.$message.error('ID已被使用！自動轉回原有ID！')
            }
        } else {
            // New class
            if (this.classIds.includes(this.offlineClass.id)) {
                this.offlineClass.id = this.lowestAvailableID
                this.$message.error('ID已被使用！自動轉至可用ID！')
            }
        }
    }

    quotaChanged(offlineClass: OfflineClass) {
        const random = ((Math.random() * (9 - 5)) + 5) / 10
        offlineClass.min_applied = Math.ceil(offlineClass.quota * random)
    }
}
