













































import {Component, Vue, Prop} from 'vue-property-decorator'
import {LessonTimeSlot} from "~/components/course/offline-course-model";
import moment from 'moment';

@Component({

})
export default class OfflineTimeslotPicker extends Vue {
    name: "offline-timeslot-picker"

    @Prop({default: ''}) title: string
    @Prop() value: LessonTimeSlot
    @Prop({ default: 0 }) lessonStartTimestamp: number
    @Prop({default: true}) fixedTime: boolean
    @Prop({default: true}) fixedLength: boolean
    @Prop({default: 0}) lessonLength: number
    @Prop({default: true}) removable: boolean

    get startTime() {
        return new Date(this.value.start)
    }

    set startTime(startTimeString) {
        const date = new Date(this.lessonStartTimestamp)
        date.setHours(new Date(startTimeString).getHours(), new Date(startTimeString).getMinutes())
        this.value.start = date.getTime()
    }

    get endTime(): Date {
        return new Date(this.value.end)
    }

    set endTime(endTimeString) {
        const date = new Date(this.lessonStartTimestamp)
        date.setHours(new Date(endTimeString).getHours(), new Date(endTimeString).getMinutes())
        this.value.end = date.getTime()
    }

    startTimePickerBlur() {
        this.$emit('startTimeChanged')
    }

    onDeleteTimeslot() {
        this.$emit('onDelete')
    }

    get startTimePickerOptions() {
        if (this.fixedLength) {
            const minutes = 24 * 60 - (this.lessonLength / (60 * 1000))
            return {
                selectableRange: '06:00:00 - ' + (Math.floor(minutes / 60)).toString().padStart(2, '0') + ':' + (minutes % 60).toString().padStart(2, '0') + ':00'
            }
        }
        return {
            selectableRange: '06:00:00 - 23:45:00'
        }
    }

    get endTimePickerOptions() {
        const min = this.startTime.getMinutes() + (process.env.VUE_APP_ENV_DEV || !!this.$route.query['test'] ? 5 : 15)
        return {
            selectableRange: this.startTime.getHours().toString().padStart(2, '0') + ':' + min.toString().padStart(2, '0') + ':00 - 23:59:00',
        }
    }
}
